var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "inspectPointImport-wrapper" },
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c("v-button", {
            attrs: { text: "返回" },
            on: { click: _vm.previous },
          }),
        ],
        1
      ),
      _c(
        "import-template",
        { attrs: { uploadUrl: _vm.uploadUrl, downloadUrl: _vm.downloadUrl } },
        [
          _c("div", { attrs: { slot: "remark" }, slot: "remark" }, [
            _c("p", [
              _vm._v("1、模板中红色字体部分为必填项，灰色部分为非必填项；"),
            ]),
            _c("p", [_vm._v("2、最大支持10MB的excel文件，超过请拆分导入。")]),
            _c("div", [
              _c(
                "label",
                {
                  staticStyle: {
                    position: "absolute",
                    left: "90px",
                    "font-weight": "700",
                  },
                },
                [_vm._v("字段说明：")]
              ),
              _c("p", [
                _vm._v(
                  "1、检查项类目名称：一级必填，最多支持五级；必须是类目表中已有的类目名称；"
                ),
              ]),
              _c("p", [_vm._v("2、检查项名称：必填，限50个字符；")]),
              _c("p", [_vm._v("3、检查项标准：非必填，限1000个字符；")]),
              _c("p", [
                _vm._v("4、检查方法及判定细则：非必填，限1000个字符；"),
              ]),
              _c("p", [_vm._v("5、状态：必选，正常，停用")]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }