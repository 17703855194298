<!--
 * @Description: 巡查管理--巡查点管理--导入页面
 * @Author: 小广
 * @Date: 2019-05-29 18:33:57
 * @LastEditors: xfj
 * @LastEditTime: 2020-03-10 10:15:33
 -->
<template>
  <div class="inspectPointImport-wrapper">
    <div class="header">
      <v-button text="返回" @click="previous"></v-button>
    </div>
    <import-template :uploadUrl="uploadUrl" :downloadUrl="downloadUrl">
      <div slot="remark">
        <p>1、模板中红色字体部分为必填项，灰色部分为非必填项；</p>
        <p>2、最大支持10MB的excel文件，超过请拆分导入。</p>
        <div>
          <label style="position: absolute;left: 90px;font-weight: 700;">字段说明：</label>
          <p>1、检查项类目名称：一级必填，最多支持五级；必须是类目表中已有的类目名称；</p>
          <p>2、检查项名称：必填，限50个字符；</p>
          <p>3、检查项标准：非必填，限1000个字符；</p>
          <p>4、检查方法及判定细则：非必填，限1000个字符；</p>
          <p>5、状态：必选，正常，停用</p> 
       </div>
      </div>
    </import-template>
  </div>
</template>
<script>
import { ImportTemplate } from 'components/bussiness/index'
import { importQualityCheckItemURL } from './api'
export default {
  components: {
    ImportTemplate
  },
  data () {
    return {
      uploadUrl: importQualityCheckItemURL,
      downloadUrl: '/static/excel/检查标准导入模板.xls'
    }
  },
  created () {
    this.$setBreadcrumb('导入检查项')
  },
  methods: {
    previous () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="scss" scoped>
  .inspectPointImport-wrapper {
    text-align: left;
    background: #fff;
  }
  .header {
    position: absolute;
    top: 20px;
    right: 20px;
    :not(:last-child) {
      margin-right: 8px;
    }
  }
</style>
